<template>
    <el-main>
        <!-- 表单 -->
        <el-form class="el-form-search" label-width="110px">
            <el-form-item label="搜索内容：">
                <el-input size="small" placeholder="请输入搜索内容：" v-model="searchForm.message"></el-input>
            </el-form-item>
            <el-form-item label=" " label-width="20px">
                <el-button type="primary" size="small" @click="getList">搜索</el-button>
                <el-button size="small" @click="exportToExcel">导出</el-button>
                <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <!-- 列表 -->
        <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" fit id="table">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="consignee" label="联系人姓名" align="center"></el-table-column>
            <el-table-column prop="consignee_mobile" label="联系人电话" align="center"></el-table-column>
            <el-table-column prop="consignee" label="图片" align="center">
                <template v-slot="{ row }">
                    <el-image :src="row.image" alt="" style="width: 60px;height: 60px;"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="服务区域" align="center">
                <template slot-scope="scope">{{ scope.row.province == '0' ? '' : scope.row.province }} {{
                    scope.row.city == '0' ? '' : scope.row.city }} {{ scope.row.area == '0' ? '' : scope.row.area
    }}</template>
            </el-table-column>
            <el-table-column prop="message" label="需求描述" align="center"></el-table-column>
        </el-table>

        <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
        </Paging>
    </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import FileSaver from "file-saver";
import XLSX from "xlsx";
export default {
    components: {
        Paging,
    },
    data () {
        return {
            searchForm: {
                page: 1,
                rows: 10,
                message: '',
            },
            total_number: 0,
            list: []
        };
    },
    filters: {
        getDateformat: getDateformat
    },
    created () {
        this.getList();
    },
    methods: {
        cancelSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                message: '',
            };
            this.getList();
        },
        getList () {
            this.$axios.post(this.$api.order.douOrderList, this.searchForm).then(res => {
                if (res.code == 0) {
                    this.total_number = res.result.total_number;
                    let list = res.result.list
                    this.list = list;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
                this.getList();
            } else {
                this.searchForm.page = val;
                this.getList();
            }
        },
        exportToExcel () {
            let fix = document.querySelector(".el-table__fixed");
            let wb = '';
            let xlsxParam = { raw: true };//转换成excel时，使用原始的格式
            if (fix) {
                //判断要导出的节点中是否有fixed的表格，如果有，转换excel时先将该dom移除，然后append回去 可以避免行重复
                wb = XLSX.utils.table_to_book(
                    document.querySelector("#table").removeChild(fix)
                    , xlsxParam);
                document.querySelector("#table").appendChild(fix);
            } else {
                wb = XLSX.utils.table_to_book(document.querySelector("#table"), xlsxParam);
            }
            let wbout = XLSX.write(wb, {
                bookType: "xlsx",
                bookSST: true,
                type: "array",
            });
            try {
                //文件名可以自定义
                FileSaver.saveAs(
                    new Blob([wbout], { type: "application/octet-stream" }),
                    "抖音需求订单收集.xlsx"
                );
            } catch (e) {
                if (typeof console !== "undefined") console.log(e, wbout);
            }
            return wbout;
        }
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;
}

.el-table {
    .userInfo {
        display: flex;
        align-items: center;
        justify-content: center;

        .el-image {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 10px;
            flex-shrink: 0;
        }

        .nickname {
            flex: 1;
            text-align: left;
        }
    }

    .imagebox {
        display: flex;
        flex-wrap: wrap;

        .el-image {
            height: 70px;
            width: auto;
            flex-shrink: 0;
            margin-bottom: 10px;
            margin-right: 10px;
        }
    }
}
</style>
